<template>
    <v-container>
      <v-row dense>
          <v-col cols="12">
              <FastOrderList/>
              <div class="text-right mt-3 mb-3">
                <router-link to="/cart" v-if="cart_items.length">
                  <v-btn text small> 
                    <v-icon small class="mr-3">fas fa-shopping-cart</v-icon>
                    Košarica
                  </v-btn>
                </router-link>
              </div>
          </v-col>
      </v-row>
    </v-container>
</template>
<script>
import FastOrderList from '../components/FastOrderList.vue'
import { mapGetters } from 'vuex'
export default {
  components: { FastOrderList },
  computed: {
    ...mapGetters('cart', {
      cart_items: 'cartProducts'
    }),
  }
};
</script>